@import url("../slick-theme.css");
@import url("../slick.css");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@font-face {
  font-family: "Brush Script MT Manual";
  src: url("../../Assets/font/BRUSHSCI.ttf");
}

/* CSS Variables */
:root {
  --logo-font: "Brush Script MT Manual";
  --primary-bg-color: black;
  --font-color: white;
  --bg-img: url("../../Assets/Images/bg.jpg");
  --section-padding: min(10vw, 10em);
  --container-padding: clamp(1.33em, 3vw, 3em);

  --row-padding: clamp(1.75em, 3vw, 3em);
  --col-padding: clamp(1.33em, 3vw, 3em);
  --col-padding-small: calc(var(--col-padding) * 0.25);

  --title-size: clamp(5em, 7.5vw, 7em);
  --text-stroke: 1px;

  --border-radius: calc(var(--title-size) * 0.25);
}

/* Global Styles */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  margin: 0;
  font-family: "Oswald", sans-serif;
  background-color: var(--primary-bg-color);
  object-position: center;
  color: var(--font-color);
  letter-spacing: 1px;
  line-height: 1.6;
  text-transform: uppercase;
  letter-spacing: 0.175rem;
}

input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: black;
  border: none;
}

button:hover {
  transform: scale(1.1);
  transition-duration: 800ms;
}

a {
  text-decoration: none;
  color: white;
}

.dots-class {
  width: 10px;
  height: 10px;
  background-color: aqua;
}

.slick-dots {
  position: relative !important;
  bottom: 3rem !important;
}

.slick-dots li button:before {
  background-color: #ffffff;
  height: 12px !important;
  width: 12px !important;
  border-radius: 5px;
  text-indent: -9999px;
  overflow: hidden;
}

lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 100%;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background: rgba(0, 0, 0, 0.834);
  z-index: 1;
}
.loader-container h2 {
  font-size: 1.5rem;
  width: 30%;
  margin: 0 auto;
  text-align: center;
  font-weight: 400;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  width: 64px;
  height: 64px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.image-card {
  width: 100%;
}

.maintenance {
  margin: 0 auto;
  width: 80%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.maintenance h1 {
  font-size: 1.8rem;
}

.maintenance p {
  font-size: 1.2rem;
  font-weight: 300;
}

.maintnanceIcon {
  font-size: 4rem;
}

.hero-video {
  margin-top: 7rem;
  width: 100%;
  height: 100%;
  max-height: 80%;
}

@media screen and (min-width: 768px) {
  .hero-video {
    height: 100%;
  }
}
@media screen and (min-width: 2180px) {
  .hero-video {
    max-height: 1200px;
    height: 100%;
  }
}

/* herovideo new */
.section {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #000;
}

.section h1 {
  position: relative;
  top: 2rem;
  font-family: "Anton", sans-serif;
  width: 90%;
  font-size: 3rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.175rem;
  font-weight: 400;
  margin: 0;
}

@media screen and (min-width: 768px) {
  .section h1 {
    position: relative;
    top: 0rem;
    font-size: 9rem;
  }
}

@media screen and (min-width: 1024px) {
  .section h1 {
    font-size: 9rem;
  }
}

.default-header {
  padding-top: 27.5vh;
  padding-bottom: calc(var(--section-padding) * 1);
}

.default-header.full-height.all-devices {
  padding: 0;
}

.default-header.full-height.all-devices .container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: calc(var(--section-padding) * 1);
  padding-bottom: calc(var(--section-padding) * 1);
}

@media screen and (max-width: 540px) {
  .default-header.full-height.all-devices .container {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
    min-height: 100svh;
  }

  .default-header.full-height.desktop {
    padding-top: 22.5vh;
    padding-top: calc(var(--vh, 1vh) * 22.5);
    padding-top: 22.5svh;
    padding-bottom: 22.5vh;
    padding-bottom: calc(var(--vh, 1vh) * 22.5);
    padding-bottom: 22.5svh;
  }
}

/* INFO - Min. Width */
@media screen and (min-width: 540px) {
  .default-header.full-height.desktop {
    padding: 0;
  }

  .default-header.full-height.desktop .container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: calc(var(--section-padding) * 1);
    padding-bottom: calc(var(--section-padding) * 1);
  }
}

.single-vimeo-background {
  --aspect-ratio-w: 16;
  --aspect-ratio-h: 9;
  --parent-height: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--parent-height) * 1vh);
  overflow: hidden;
  background: var(--color-dark);
  transform: translateZ(0);
  isolation: isolate;
  pointer-events: none;
  /* border-radius: 0 0 calc(var(--border-radius) * 1.5)
    calc(var(--border-radius) * 1.5);
  border-radius: calc(var(--border-radius) * 1.5); */
}

.single-vimeo-background iframe {
  width: 100vw;
  height: calc(
    var(--aspect-ratio-h) / var(--aspect-ratio-w) * (var(--parent-height) * 1vw)
  );
  min-height: calc(var(--parent-height) * 1vh);
  min-width: calc(
    var(--aspect-ratio-w) / var(--aspect-ratio-h) * (var(--parent-height) * 1vh)
  );
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Placeholder */

.single-vimeo-background .vimeo-overlay-placeholder {
  background: var(--color-dark);
  transition: opacity 1s ease-in-out;
  opacity: 1;
}

.single-vimeo-background .vimeo-overlay-placeholder.video-box video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Mobile / Dekstop */

.single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile {
  display: none;
}

@media screen and (max-width: 1024px) {
  .single-vimeo-background {
    --parent-height: 100;
  }

  .single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile,
  .single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile video {
    height: calc(var(--parent-height) * var(--vh, 1vh));
  }

  .single-vimeo-background .vimeo-overlay-placeholder.placeholder-desktop {
    display: none;
  }

  .single-vimeo-background .vimeo-overlay-placeholder.placeholder-mobile {
    display: block;
  }

  /* Edited for mobile 22 FEB 2023 */

  .home-header .shape-polygon {
    display: none !important;
  }

  .home-header > .single-vimeo-background {
    scale: 1 1 !important;
  }
}

.shape-polygon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: calc(100% - (var(--col-padding) * 4));
  aspect-ratio: 816/1000;
  /* clip-path: polygon(24% 0, 67% 0, 86% 11%, 100% 100%, 19% 100%, 0 88%); */
  clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.shape-polygon .shape-polygon-inner {
  position: absolute;
  width: 100vw;
  height: 100vh;
  transform: rotateY(180deg);
  -webkit-transform: rotateY(180deg); /* Safari and Chrome */
  -moz-transform: rotateY(180deg); /* Firefox */
}

@media (max-aspect-ratio: 8/10) {
  .shape-polygon {
    height: unset;
    width: 66.666%;
  }
}

@media screen and (max-width: 540px) {
  .shape-polygon .shape-polygon-inner {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    height: 100svh;
  }
}

.mousey {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 3px;
  padding: 5px 11px;
  height: 30px;
  border: 2px solid #fff;
  border-radius: 25px;
  opacity: 0.75;
  box-sizing: content-box;
}
.scroller {
  width: 3px;
  height: 10px;
  border-radius: 25%;
  background-color: #fff;
  animation-name: scroll;
  animation-duration: 2.2s;
  animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
  animation-iteration-count: infinite;
}
@keyframes scroll {
  0% {
    opacity: 0;
  }
  10% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(15px);
    opacity: 0;
  }
}

.fotoLoja2 {
  object-position: 0 -60px !important;
}

.fotoLoja4 {
  object-position: 0 -70px !important;
}

/* breakpoints */

@media screen and (max-width: 2024px) {
  .fotoLoja2 {
    object-position: center !important;
  }

  .fotoLoja4 {
    object-position: center !important;
  }
}

.canvas {
  position: relative !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
