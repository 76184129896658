.navbar {
  position: fixed;
  left: 0;
  right: 0;
  height: 110px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: transparent;
  transition-duration: 0.6s;
}
.navbarBlack {
  position: fixed;
  left: 0;
  right: 0;

  height: 110px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  background-color: black;
  transition-duration: 0.6s;
}

.navbarCenter {
  width: 87%;
  /* max-width: 1800px !important; */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
}

.LinksGrid {
  display: none;
}
/* .navbar{
    position: absolute;
    left: 0;
    right: 0;
    width: 87%;
    height: 100px;
    max-width: 1600px;
    margin: 0.8rem auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 999;

} */

.navLogo {
  display: flex;
  flex-direction: column;
  position: relative;
  bottom: 0.8rem;
}

.navLogo h1 {
  position: relative;
  top: 0.6rem;
  width: 100%;
  font-family: var(--logo-font);
  letter-spacing: 2px;
  font-size: 35px;
  text-transform: capitalize;
}
.navLogoBottom {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  text-transform: uppercase;
  font-weight: 800;
  gap: 1rem;
}

.navLogoBottom span {
  text-align: center;
  padding-top: 1rem;
  font-size: 0.8rem;
}
.navLogo .squareLeft {
  margin-top: 1.5rem;
  width: 20px;
  height: 20px;
}

.navLogo .squareRight {
  margin-top: 1.5rem;
  width: 20px;
  height: 20px;
  transform: rotate(270deg);
}

.navLinks {
  display: none;
}

.navIcons {
  display: none;
}

.mobileMenu {
  font-size: 3rem;
  position: relative;
  top: 0.6rem;
  cursor: pointer;
  animation: ease-in-out;
}

.mobileMenu:hover {
  transform: scale(1.2);
  animation-timing-function: linear;
  transition-duration: 1000ms;
}

@media screen and (min-width: 1160px) {
  .mobileMenu {
    display: none;
  }
  .LinksGrid {
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
  }

  .LinksGrid a {
    letter-spacing: 0.2rem;
  }

  .LinksGrid a:hover {
    border-bottom: 2px solid #a9a9a9;

    animation-timing-function: linear;
    transition-duration: 700ms;
  }
  .navLogo h1 {
    position: relative;
    top: 1rem;
    width: 100%;
    font-family: var(--logo-font);
    letter-spacing: 2px;
    font-size: 40px;
    text-transform: capitalize;
  }

  .mobileMenu {
    font-size: 3rem;
    position: relative;
    top: 0.6rem;
    cursor: pointer;
  }
}
