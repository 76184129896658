.Galeria {
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  height: 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.Galeria section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  max-width: 1300px;
  margin: 0 auto;
}

.Galeria section h2 {
  margin-top: 5rem;
  font-size: 1.8rem !important;
  margin-bottom: 4rem;
}

.Galeria .grid {
  display: block;
  width: 87%;
  max-height: 100%;
  display: grid;
  /* grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: repeat(auto-fill, 290px); */
  /* grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(auto-fill, 390px); */

  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(4, 150px);
  grid-auto-flow: dense;
  justify-content: center;
  align-items: center;
  margin: 0rem auto;
  gap: 1rem;
}

.grid img {
  opacity: 0.85;
  width: 100%;
}

.grid img:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1.05);
  border-radius: 1rem;
  opacity: 1;
  animation-timing-function: linear;
  transition-duration: 500ms;
}
.serviçosTitle {
  margin-top: 3rem;
  font-size: 1.4rem !important;
}

@media screen and (min-width: 768px) {
  .Galeria {
    margin-bottom: 4rem;
  }

  .Galeria .grid {
    width: 87%;
    /* grid-template-columns: repeat(3, minmax(10%, 1fr)); */
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 300px);
  }
}
@media screen and (min-width: 1200px) {
  .Galeria {
    margin-bottom: 4rem;
  }

  .Galeria .grid {
    width: 87%;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, 350px);
  }
}

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Stay in place */
  z-index: 999;
  /* Sit on top */

  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;

  min-height: -webkit-fill-available;
  /* Full height */
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.2);
  /* Black w/ opacity */
}

/* Modal Content (Image) */
.modalContent {
  margin: auto;
  display: block;
  width: 87%;
  max-width: 600px;
}

.modal img {
  opacity: 1;
  transform: none;
  border: none !important;
  border-radius: none !important;
}

.modal img:hover {
  cursor: none;
  transform: none;
  border-radius: 0;
}

.nextFoto {
  position: absolute;
  top: 630px;
  right: 250px;
  width: 80px;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
}

/* Add Animation - Zoom in the Modal */
.modalContent {
  animation-name: zoom;
  animation-duration: 0.6s;
}

@keyframes zoom {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* The Close Button */
.close {
  display: none;
  position: absolute;

  top: 5%;
  right: 15%;
  color: #f1f1f1;
  font-size: 5rem;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .modalContent {
    margin: auto;
    display: block;
    width: 100%;
    max-width: 550px;
  }

  .close {
    display: block;
  }
}
