.fotosPage {
  padding-top: 6rem;
  padding-bottom: 4rem;
  max-width: 1600px;
  margin: 0 auto;
}

.fotosPage .header {
  padding-top: 2rem;
  text-align: center;
}
.buttonActive {
  color: #a9a9a9;
  border-bottom: 2px solid #a9a9a9;
}
.buttonDesactive {
  color: white;
}

.header span {
  opacity: 0.8;
}
.socialMedia h4 {
  font-size: 1.2rem;
}

.buttonSection {
  width: 87%;
  height: 100%;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.buttonSection button {
  background-color: transparent;
  color: white;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 0.9rem !important;
  cursor: pointer;
  letter-spacing: 0.14rem;
  text-transform: uppercase !important;
}

.galeriaGrid {
  padding-top: 3rem;
  padding-bottom: 4rem;
  width: 87%;
  max-width: 1600px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-auto-flow: dense;
  margin: 0 auto;
  gap: 1rem;
}

.galeriaGrid img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  cursor: pointer;
}

.horizontal {
  grid-column: span 2;
  width: calc(2 * (100% + 15px) - 15px);
  height: 100%;
}

.galeriaGrid img:hover {
  cursor: pointer;
  opacity: 1;
  transform: scale(1.05);
  border-radius: 1rem;
  opacity: 1;
  animation-timing-function: linear;
  transition-duration: 500ms;
}

.socialMedia {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-bottom: 4rem;
}

.Icons {
  display: flex;
  gap: 1.5rem;
  font-size: 2rem;
}
.whatsapp {
  position: relative;
  top: 2px;
  font-size: 2.6rem;
}

.whatsapp:hover {
  transform: scale(1.1);
  transition-duration: 500ms !important;
}

.ico:hover {
  transform: scale(1.1);
  transition-duration: 500ms !important;
}
.moreImagesBtn {
  background-color: transparent;
  color: black;
  background-color: antiquewhite;
  text-transform: capitalize;
  font-weight: 400;
  font-size: 1.1rem;
  cursor: pointer;
  letter-spacing: 0.14rem;
  text-transform: uppercase !important;
  border: 1px solid white;
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  transition-duration: 500ms;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 2rem;
}

.moreImagesBtn:hover {
  background-color: black;
  color: white;
  transform: translateX(-50%);
}

@media screen and (min-width: 1024px) {
  .buttonSection button {
    background-color: transparent;
    color: white;
    text-transform: capitalize;
    font-weight: 400;
    font-size: 1.1rem;
    cursor: pointer;
  }
  .header h1 {
    font-size: 2rem;
  }
  .header span {
    font-size: 1.3rem;
  }
  .galeriaGrid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(auto-fit, 1fr);
    grid-auto-flow: dense;
  }

  .galeriaGrid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .horizontal {
    grid-column: span 2;
    width: calc(2 * (100% + 15px) - 15px);
    height: 100%;
  }

  .socialMedia h4 {
    font-size: 1.3rem;
  }

  .Icons {
    display: flex;
    gap: 1.4rem;
    font-size: 2.39rem;
  }
  .whatsapp {
    font-size: 2.65rem;
    position: relative;
    bottom: 3px;
  }
}

.modal {
  display: none;

  position: fixed;

  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  z-index: 999;
  min-height: -webkit-fill-available;

  overflow: auto;

  background-color: rgb(0, 0, 0);

  background-color: rgba(0, 0, 0, 0.2);
}

/* 100% Image Width on Smaller Screens */

@media screen and (min-width: 768px) {
  .modalContent {
    margin: auto;
    display: block;
    max-width: 100%;
    padding: 20rem;
  }

  .modalContent img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .close {
    display: block;
  }
}
