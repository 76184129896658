.espacolojaPage {
    padding-top: 6rem;
    padding-bottom: 6rem;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    
}

.espacolojaPage .header{
    margin-top: 2rem;
    margin-bottom: 2rem;
    text-align: center;
}
.header span {
    opacity: 0.8;
}
.ImgLoja{
    width: 60%;
    
}
.lojaParagrafo{
    width: 87%;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    font-size: 0.9rem;
    font-weight: 100;
    opacity: 0.8;
}




.espacolojaPage .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4,180px);
    gap: 0.8rem;
    justify-content: center;
    align-items: center;
    
}
.espacolojaPage .grid img{
    width: 100% !important;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.espacolojaPage .grid img:nth-of-type(1){
    grid-column: 1/-1;
}
.espacolojaPage .grid img:nth-of-type(6){
    grid-column: 1/-1;
}

.morada{
    margin-top: 4rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
}
.morada h3{
    font-size:1rem ;
}
.morada span{
    font-size: 0.9rem;
    width: 50%;
    font-weight: 100;
    opacity: 0.7;
}

.spanBtnSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}
.spanBtnSection h4{
    font-size: 1.2rem;
}

.spanBtnSection button {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    cursor: pointer;
}

.spanBtnSection button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    background: rgb(112, 111, 111);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}


.spanBtnSection button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.spanBtnSection button:active {
    color: #000
}

.spanBtnSection button:active:after {
    background: transparent;
}

.spanBtnSection button:hover:before {
    opacity: 1;
}

.spanBtnSection button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(227, 227, 227);

    left: 0;
    top: 0;
    border-radius: 10px;
}
.spanBtnSection button:hover {
    transform: scale(1.1);
    transition-duration: 1000ms !important;
}


@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@media screen and (min-width: 768px) {
.header h1 {
        font-size: 2rem;
    }

    .header span {
        font-size: 1.3rem;
    }

       

    
    .espacolojaPage .lojaParagrafo{
        width: 68%;
        font-size: 1.2rem;
    }


        .espacolojaPage .grid {
            width: 86%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 280px 280px 350px 350px 280px 280px;
            gap: 0.8rem;
            justify-content: center;
            align-items: center;
    
        }

        .espacolojaPage .grid img:nth-of-type(1) {
            grid-column: 1/-1;
            grid-row: 1/3;
        }

        .espacolojaPage .grid img:nth-of-type(6) {
            grid-column: 1/-1;
            grid-row: 5/-1;
        }

    /* .espacolojaPage .grid {
        width: 87%;
        grid-template-columns: 1fr 1fr ;
        grid-template-rows: repeat (3, 350px);
        justify-content: center;
        align-items: center;
        gap: 1rem;
} */

    /* .espacolojaPage .grid img{
        margin: 0 auto;
        width: 100%;
        height: 100%;
} */

.morada h3{
    font-size: 1.3rem;
}

.morada span{
    font-size: 1.1rem;
}
.spanBtnSection {
    font-size: 1.1rem;
}

.spanBtnSection button {
    padding: 0.7rem 4rem;
    font-size: 1.1rem;
    cursor: pointer;
}


}


@media screen and (min-width: 1300px) {
    .espacolojaPage .lojaParagrafo {
        width: 52%;
        font-size: 1.2rem;
    }
/* .espacolojaPage .grid {
        grid-template-columns: 350px 350px;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    } */


}