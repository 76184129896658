.videosPage {
    padding-top: 6rem;
    padding-bottom: 3rem;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
}

.videosPage .header {
    margin-top: 5rem;
    text-align: center;
}
.header span {
    opacity: 0.8;
}

.sectionVideo {
    padding-top: 2rem;
    margin: 0 auto;
    width: 87%;
    max-width: 1600px;
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 1fr));
    grid-template-rows: repeat(auto-fit, 600px);
    grid-auto-flow: dense;
    justify-content: center;
    align-items: center;
    gap: 4rem;
}

.sectionVideo iframe {
    width: 100%;
    max-width: 600px;
    display: block;
    margin: 0 auto;
    
}

.videoGrid{
    width: 87%;
    margin: 0 auto;   
}

.gridText{
    width: 100%;
    display:flex ;
    flex-direction: column;
    gap: 0.5rem;
    margin: 0 auto;
     
}
.gridText span {
font-size: 1rem;
font-weight: 400;
}
.gridText p{
    font-size: 0.9rem;
    font-weight: 100;
    text-align: left;
    opacity: 0.8;
}

.spanBtnSection {
    margin-top: 3rem;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}

.spanBtnSection button {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    cursor: pointer;
}


.spanBtnSection button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 250px;
    height: 50px;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    background: rgb(112, 111, 111);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.spanBtnSection button:hover {
    transform: scale(1.1);
    transition-duration: 1000ms !important;
}

.spanBtnSection button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.spanBtnSection button:active {
    color: #000
}

.spanBtnSection button:active:after {
    background: transparent;
}

.spanBtnSection button:hover:before {
    opacity: 1;
}

.spanBtnSection button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(227, 227, 227);

    left: 0;
    top: 0;
    border-radius: 10px;
}



@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@media screen and (min-width: 900px) {


.sectionVideo {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, minmax(100px, 1fr));
        grid-auto-flow: dense; 
        gap:0;
    }
.sectionVideo iframe{
    width: 100%;
    max-width: 500px;
    height: 17rem !important;
 
}

/* .sectionVideo iframe video{
    height: 500px !important;
} */


.header h1 {
    font-size: 2rem;
}

.header span {
    font-size: 1.3rem;
}

.spanBtnSection {
    font-size: 1.4rem;
}
  .spanBtnSection button {
      padding: 0.7rem 4rem;
      font-size: 1.2rem;
      cursor: pointer;
  }
.gridText p {
    font-size: 1rem;
    
}
.gridText span {
    font-size: 1.1rem;
    
}


}
@media screen and (min-width: 1500px) {
.sectionVideo {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(3, minmax(100px, 1fr));
        grid-template-rows: repeat(auto-fit, 600px);
        grid-auto-flow: dense;
    }

    .sectionVideo iframe {
        width: 100%;
        max-width: 700px;
    }

}