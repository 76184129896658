.sobrePage{
    padding-top: 9rem;
    padding-bottom: 6rem;
    width: 100%;
    max-width:1600px ;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.sobrePage section{
    margin-top: 2rem;
    margin-bottom: 4rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    text-align: center;
    margin: 0 auto;
    
}
.sobrePage section h1 {
    font-size: 1.4rem;
    padding-bottom: 1.2rem;
}

.sobrePage section img{
    width: 100%;
    object-fit: cover;
    margin-bottom: 2rem;
}

.sobrePage p{
    width: 84%;
    font-weight: 100;
    opacity: 0.8;
    text-align: justify;
    margin: 0 auto ;
    padding-bottom: 3rem;
    font-size: 0.9rem;
}


.spanBtnSection {
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}

.spanBtnSection button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
}
.spanBtnSection button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    background: rgb(112, 111, 111);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.spanBtnSection button:hover{
    transform: scale(1.1);
    transition-duration: 1000ms !important;
}



.spanBtnSection button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.spanBtnSection button:active {
    color: #000
}

.spanBtnSection button:active:after {
    background: transparent;
}

.spanBtnSection button:hover:before {
    opacity: 1;
}

.spanBtnSection button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(227, 227, 227);

    left: 0;
    top: 0;
    border-radius: 10px;
}



@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

@media screen and (min-width: 768px) {
    .spanBtnSection {
            font-size: 1.1rem;
    }
    .sobrePage section h1{
        
        padding-bottom: 1.2rem;
    }

    .sobrePage section img {
    object-fit: cover;
    height: 40vh !important;   
    }

    .spanBtnSection button {
        padding: 0.7rem 4rem;
        font-size: 1.2rem;
        cursor: pointer;
    }
        .sobrePage p {
            width: 84%;
            font-size: 1rem;
            text-align: justify;
            margin: 0 auto;
            padding-bottom: 3rem;
        }
}


@media screen and (min-width: 1200px) {
    .sobrePage section{
        flex-direction: row;
        width: 87%;
    }

        .spanBtnSection {
            font-size: 1.2rem;
        }


    .sobrePage section img {
            width: 50%;           
        }

}