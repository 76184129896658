.modal {
  position: fixed;
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #000000ef;
  z-index: 999;
  overflow: hidden;
}

.modalCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #000000db;
  width: 94%;
}

.modalCenter img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.modalCenter img:hover {
  transform: scale(1);
  border: none;
  border-radius: 0px;
  outline: none;
}

.modal .back {
  position: absolute;
  top: 50%;
  left: 7%;
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
}

.modal .next {
  position: absolute;
  top: 50%;
  right: 7%;
  font-size: 2.5rem;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
}

.modal .close {
  position: absolute;
  top: 2.6rem;
  right: 2.6rem;
  font-size: 2rem;
  color: #fff;
  cursor: pointer;
  background-color: transparent;
}

button:hover {
  transform: scale(1.1);
  background-color: transparent !important;
}

/* brekpoints */

@media screen and (min-width: 768px) {
  .modalCenter {
    width: 80%;
    height: 80%;
  }
  .modal .back {
    position: absolute;
    top: 50%;
    left: 2%;
    font-size: 2.5rem;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
  }

  .modal .next {
    position: absolute;
    top: 50%;
    right: 2%;
    font-size: 2.5rem;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
  }
}

@media screen and (min-width: 1024px) {
  .modalCenter {
    width: 90%;
    height: 90%;
  }
}
@media screen and (min-width: 2500px) {
  .modal .back {
    position: absolute;
    top: 50%;
    left: 10%;
    font-size: 2.5rem;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
  }

  .modal .next {
    position: absolute;
    top: 50%;
    right: 10%;
    font-size: 2.5rem;
    color: #fff;
    cursor: pointer;
    background-color: transparent;
  }
}
