.sidebar {
    background: black;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    opacity: 0;
    transition: all 0.2s linear;
    transform: translateX(-100%);
}

.showSidebar {
    opacity: 1;
    transform: translateX(0);
}

.sidebarLinks li {
    list-style: none;
}

.sidebarLinks li a {
    text-decoration: none;
    display: block;
    text-align: center;
    text-transform: uppercase;
    color: white;
    margin-bottom: 0.5rem;
    font-size: 2rem;
    transition: all 0.2s linear;
    
}

.sidebarLinks li a:hover {
    background: grey;
    transition-duration: 500ms !important;
    /* color: var(--clr-bg-1); */
}

.closeBtn{
    position: absolute;
    right: 4%;
    top: 2.4%;
    font-size: 4rem;
    background: transparent;
    border-color: transparent;
    color: white;
    cursor: pointer;
}
.closeBtn:hover{
    color: grey;
    transform: scale(1.1);
    transition-duration: 500ms !important;
}

.sidebarIcons{
    display: flex;
    gap: 1.5rem;
    font-size: 2.35rem;
}

.whatsapp{
    position: relative;
    top: 2px;
    font-size: 2.6rem;
}
.whatsapp:hover{
    transform: scale(1.1);
    transition-duration: 500ms !important;
}

.ico:hover {
    transform: scale(1.1);
    transition-duration: 500ms !important;
   
}

