.whatsappFloat {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 40px;
    right: 40px;
    background: linear-gradient(rgb(57,
        255,
        51) 0%, rgb(63,
            200,
            58) 30%, rgb(39,
                104,
                37) 100%);
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 27px;
    /* box-shadow: 2px 2px 3px #999; */
    z-index: 100;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    transform: scale(1);
    animation: pulse-black 2s infinite;
    cursor: pointer;
}

.whatsappFloat:hover{
    transform: scale(1.2);
    animation: ease-in-out 1000ms;
    animation-timing-function: linear;
    transition-duration: 1000ms;
}


/* for mobile */
@media screen and (max-width: 767px) {
    .whatsappIcon {
        margin-top: 5px;
    }

    .whatsappFloat {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 25px;
        font-size: 28px;
    }
}

.pulse {
    animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
    0% {
        box-shadow: 0 0 0 0px rgba(203, 203, 203, 0.676);
    }

    100% {
        box-shadow: 0 0 0 15px rgba(73, 72, 72, 0.052);
    }
}