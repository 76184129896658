.albunsPage {
  padding-top: 6rem;
  padding-bottom: 2rem;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.albunsPage .header {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}
.header span {
  opacity: 0.8;
}

.albunsPage .grid {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.grid img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.albunsPage .grid2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.albunsPage .grid2 p {
  padding-top: 2rem;
  padding-bottom: 2rem;
  width: 85%;
  text-align: center;
  font-weight: 100;
  opacity: 0.8;
  font-size: 0.9rem;
}

.grid2 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.spanBtnSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.2rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.spanBtnSection button {
  padding: 0.5rem 2rem;
  font-size: 1rem;
  cursor: pointer;
}

.albunsPage .grid3 {
  display: grid;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.grid3 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.spanBtnSection button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 220px;
  height: 50px;
  border: none;
  outline: none;
  color: rgb(0, 0, 0);
  background: rgb(112, 111, 111);
  cursor: pointer;
  position: relative;
  z-index: 0;
  border-radius: 10px;
}

.spanBtnSection button:before {
  content: "";
  background: linear-gradient(
    45deg,
    #ff0000,
    #ff7300,
    #fffb00,
    #48ff00,
    #00ffd5,
    #002bff,
    #7a00ff,
    #ff00c8,
    #ff0000
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing 20s linear infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.spanBtnSection button:active {
  color: #000;
}

.spanBtnSection button:active:after {
  background: transparent;
}

.spanBtnSection button:hover:before {
  opacity: 1;
}

.spanBtnSection button:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(227, 227, 227);

  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 400% 0;
  }

  100% {
    background-position: 0 0;
  }
}

@media screen and (min-width: 768px) {
  .albunsPage .header {
    margin-top: 2rem;
    margin-bottom: 4rem;
  }

  .header h1 {
    font-size: 2rem;
  }

  .header span {
    font-size: 1.3rem;
  }

  .grid2 p {
    width: 66% !important;
    font-size: 1.26rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .grid2 img {
    width: 87%;
    max-width: 1600px;
    height: 100%;
    object-fit: cover;
  }

  .spanBtnSection {
    font-size: 1.1rem;
  }

  .spanBtnSection button {
    padding: 0.7rem 4rem;
    font-size: 1.1rem;
    cursor: pointer;
  }
}
