.cookies h3{
    letter-spacing: 1px;
}

.cookies p{
    font-size: 0.9rem;
    letter-spacing: 1px;
    text-align: left;
}

.cookies a{
    color: #917729;
}
.btnCookie{
    transform: none !important;
}

.btnCookie:hover{
    background-color: white !important;
    color: #000 !important;
    transition-duration: 200ms;
}





.videosFantasticos{
    padding-top: 7rem;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    width: 100%;
    
}


.videosFantasticos .icon {
    font-size: 3.5rem;
}

.videosFantasticos h2 {
    font-size: 1.2rem;
    font-weight: 600;
}

.videosFantasticos p{
    font-size: 0.9rem;
    opacity: 0.8;
    font-weight: 100 ;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
}


.videoPlayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
    padding-bottom: 3rem;
    overflow: hidden;
    position: relative;
    

}
.serviçosTitle{
    text-align: center;
    padding-top: 2em;
    padding-bottom: 3em;
    font-size: 1.4rem !important;
}

.fotografiaFantastica {
    margin: 0 auto;
    padding-bottom: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 1rem;
    width: 87%;
    max-width: 1130px;
        

}

.fotoSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.6rem;
    
}

.fotoSection .icon{
    font-size: 3.5rem;
    margin-top: 2rem;
}
.fotoSection h2 {
    font-weight: 600;
    font-size: 1.2rem;
}

.fotoSection p {
    margin: 0 auto;
    padding-top: 0.7rem;
    font-size: 0.9rem;
    font-weight: 100;
    opacity: 0.8;
    width: 80%;
    padding-bottom: 2rem;

}
.fotoSection img {
    width: 100%;
}
.fotoSectionE {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 0.6rem;
    
}

.fotoSectionE .icon{
    font-size: 3.5rem;
    margin-top: 2rem;
}
.fotoSectionE h2 {
    font-weight: 600;
    font-size: 1.2rem;
}

.fotoSectionE p {
    padding-top: 0.7rem;
    margin: 0 auto;
    font-size: 0.9rem;
    font-weight: 100;
    opacity: 0.8;
    width: 80%;
    padding-bottom: 2rem;

}
.fotoSectionE img {
    width: 100%;
}

.fotoSection .linkSpan{
    text-decoration: none;
    color: aliceblue;
    width: 90%;
    text-align: right;
    font-size: 0.7rem;
    cursor: pointer;
}

.spanBtnSection {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.2rem;
}



.spanBtnSection button {
    padding: 0.5rem 2rem;
    font-size: 1rem;
    cursor: pointer;
    
}


.spanBtnSection button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: rgb(0, 0, 0);
    background: rgb(112, 111, 111);
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;

}


.spanBtnSection button:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
    
}

.spanBtnSection button:active {
    color: #000
}

.spanBtnSection button:active:after {
    background: transparent;
}

.spanBtnSection button:hover:before {
    opacity: 1;
    
}

.spanBtnSection button:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgb(227, 227, 227);

    left: 0;
    top: 0;
    border-radius: 10px;
   
}



@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}




















.navLogo {
    display: flex;
    flex-direction: column;
    position: relative;
    bottom: 0.6rem;
}

.navLogo h1 {
    font-family: var(--logo-font);
    letter-spacing: 2px;
    font-size: 45px;
    text-transform: capitalize;

}

@media screen and (min-width: 768px) {
    /* .fotografiaFantastica {
        margin: 0 auto;
        width: 87%;
        max-width: 1600px;
    
    } */
.videosFantasticos{
    margin: 0 auto;
    width: 80%;
    max-width: 1500px;

}

.videosFantasticos p{
    width: 80%;
}

.videosFantasticos img {
    width: 70%;
}

.videoPlayer{
    width: 100%;
    height: 500px;
    object-fit: cover;
    object-position: center center no-repeat;
    /* padding-bottom: 2rem; */
    /* width:650px; */
    margin-bottom: 2rem;
    
}


.fotografiaFantastica p{
    width: 100%;
    font-size: 1rem ;
}
.fotografiaFantastica h2{
    font-size: 1.2rem ;
}

.fotografiaFantastica img{
    width: 500px;
}
.fotoSection{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    gap: 2rem;
    margin-bottom: 3rem;
}

.fotoSection img{
    width: 60%;
}
.fotoSectionE img{
    width: 60%;
}

.fotoSectionE{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    text-align: right;
    gap: 2rem;
    margin-bottom: 3rem;
}

.fotoSectionE p{
    text-align: right;
    
}


.fotoSection p{
    text-align: left;
   
}


.fotoSection .icon {
    font-size: 2.8rem;
    margin-top: 2rem;
}

.fotoSectionE .icon {
    font-size: 2.8rem;
    margin-top: 2rem;
}

.fotoSection h2 {
    font-weight: 600;
   
}
.fotoSectionE h2 {
    font-weight: 600;
    
}
.fotoSection img {
    width: 60%;
}

.fotoSectionE img {
    width: 60%;
}
}

@media screen and (min-width: 1024px) {

    .videosFantasticos{
        width: 50%;
    }
    .videosFantasticos p{
        width: 85%;
    }

.fotoSection{
    gap: 3rem;
}
.fotoSectionE{
    gap: 3rem;
}



.fotoSectionE p {
        text-align: right;
        
    }


    .fotoSection p {
        text-align: left;
        
        
    }
.fotoSection .icon {
    font-size:3rem;
    margin-top: 2rem;
}

.fotoSectionE .icon {
    font-size: 3rem;
    margin-top: 2rem;
}

.fotoSection h2 {
    font-weight: 600;
    
}

.fotoSectionE h2 {
    font-weight: 600;
    
}
.fotoSection img {
    width: 60%;
}

.fotoSectionE img {
    width: 60%;
}



}


@media screen and (min-width: 1250px) {

    .videosFantasticos p {
            width: 95%;
        }
    .fotografiaFantastica{
        margin: 0 auto;
        
    }
.fotoSection {
        gap: 8rem;
    }

    .fotoSectionE {
        gap: 8rem;
    }


    .fotoSectionE p {
            text-align: right;
            
        }
    
    
        .fotoSection p {
            text-align: left;
            
    
        }

.fotoSection img {
        width: 40%;
    }

    .fotoSectionE img {
        width: 40%;
    }

}