.footerMain {
  height: 100%;
  width: 100%;
  max-width: 1600px;
  /* display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 200px 100% 1fr; */
  text-align: center;
  margin: 0 auto;
  gap: 2rem;
}

.logoFooterSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logoFooterSection img {
  width: 50%;
  margin-bottom: 1rem;
  margin-top: 3rem;
}

.navIcons {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  padding-bottom: 2rem;
  margin: 0 auto;
}

.spanMain {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 0.7rem;
}
.googleImg {
  width: 100%;
  height: 13rem;
}
.developedbycarlos a {
  color: rgb(34, 158, 38);
}

.navIcons a .ico:hover {
  transform: scale(1.1);
  animation-timing-function: linear;
  transition-duration: 500ms;
}

.whatsapp {
  position: relative;
  top: 0px;
  font-size: 2.6rem;
}

.whatsapp:hover {
  transform: scale(1.1);
  animation-timing-function: linear;
  transition-duration: 500ms;
}

@media screen and (min-width: 768px) {
  .googleImg {
    width: 100%;
    height: 20rem;
  }

  .logoFooterSection img {
    /* width: 250px; */
    width: 20%;
  }
}
